import { useEffect, useState } from 'react';
import {Box, Button, Divider, IconButton, MenuItem, Typography} from '@mui/material';
import { shortenAddress } from '../../utils/shortenAddress';
import Iconify from '../iconify';
import MenuPopover from '../menu-popover';
import { dispatch } from '../../redux/store';
import { clearNetworks } from '../../redux/slices/network';
import { clearTokenPrices } from '../../redux/slices/tokentPrice';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { LoadingButton } from '@mui/lab';
import ReactGA from 'react-ga4';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import {useSnackbar} from '../snackbar';

function ConnectButton() {
  const { connect, error, connectors, pendingConnector, data } = useConnect();
  const { disconnect } = useDisconnect();
  const { open, isOpen } = useWeb3Modal();
  const { address, isConnected, status } = useAccount();
  const { copy } = useCopyToClipboard();
  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const onCopy = (text: string) => {
    if (text) {
      enqueueSnackbar('Wallet Address copied!', {anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }});
      copy(text);
    }
  };

  const handleLogout = async () => {
    try {
      handleClosePopover();
      dispatch(clearNetworks());
      dispatch(clearTokenPrices());
      disconnect();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (status === 'connected') {
      ReactGA.event('connect_wallet', {
        status: 'connected',
        address: `_${address}`,
      });
      // ReactGA.event({
      //   category: 'connect_wallet',
      //   action: 'wallet_connected',
      // });
    }
  }, [status]);

  const connectToWallet = async () => {
    await open();
  };

  return isConnected && address ? (
    <>
      <Button variant="contained" onClick={handleOpenPopover}>
        <Iconify icon="uil:wallet" />
        <Box component={'span'} sx={{ ml: 1 }}>
          {shortenAddress(address)}
        </Box>
      </Button>
      <MenuPopover
        disabledArrow={true}
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, mt: 0.5, p: 0.5 }}
      >
        <MenuItem onClick={() => onCopy(address)} sx={{ display: 'flex',px: '16px',alignItems:'center' ,justifyContent: 'space-between'}}>
          <Box sx={{display: 'flex',alignItems:'center'}}>
          <Iconify icon="eva:copy-fill" width={24}/>
          <Box component={'span'}>
            {shortenAddress(address)}
          </Box>
          </Box>
          <Typography
              component={'span'}
              variant={'caption'}
              color={'text.secondary'}
              pl={2}
          >
            Copy
          </Typography>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleLogout} sx={{ display: 'flex', px: '16px'}}>
          <Iconify icon="fa6-solid:power-off" width={24} />
          <Box component={'span'}>Disconnect</Box>
        </MenuItem>
      </MenuPopover>
    </>
  ) : (
    <LoadingButton
      sx={{ width: '150px' }}
      onClick={connectToWallet}
      variant="contained"
      loadingIndicator={<span style={{ whiteSpace: 'nowrap' }}>Connecting…</span>}
      loading={isOpen}
    >
      Connect Wallet
    </LoadingButton>
  );
}

export default ConnectButton;