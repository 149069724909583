// @mui
import {Card, CardContent, Container, Stack} from '@mui/material';
// auth
// _mock_
// components
import {useSettingsContext} from '../../components/settings';
// sections
import {NetworkBalances} from '../../sections/@dashboard/general/e-commerce';
// assets

// ----------------------------------------------------------------------
import React, {useEffect, useRef, memo} from 'react';
import {Helmet} from 'react-helmet-async';
import useScript from '../../hooks/useScript';
import {useTheme} from '@mui/material/styles';

function NewsWidget() {
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <script src="https://static.cryptopanic.com/static/js/widgets.min.js"></script>
            </Helmet>
            <Card>
                <CardContent sx={{padding: '0 !important'}}>
                    <a href="https://cryptopanic.com/" target="_blank" data-news_feed="recent"
                       data-bg_color={theme.palette.background.paper}
                       data-text_color={theme.palette.text.primary} data-link_color={theme.palette.primary.main}
                       data-header_bg_color={theme.palette.background.neutral}
                       data-header_text_color={theme.palette.text.secondary} data-posts_limit="3"
                       className="CryptoPanicWidget">Latest
                        News</a>
                </CardContent>
            </Card>
        </>
    );
}

export default NewsWidget;



