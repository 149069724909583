// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Stack, Toolbar } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
import Wallet from '../../../sections/@dashboard/user/account/Wallet';
import { dispatch, useSelector } from '../../../redux/store';
import { useLocation } from 'react-router-dom';
import { getNetwork, selectAllNetworkTokensSelector, selectNetworkSelector } from '../../../redux/slices/network';
import { getTokenPrices } from '../../../redux/slices/tokentPrice';
import { useAccount } from 'wagmi';
import { getNetworks } from '../../../redux/slices/analytics';
import { CHAIN_ID } from '../../../assets/data/networks';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Beta from '../../../components/logo/Beta';
import {getStablecoins} from '../../../redux/slices/stablecoin';

// ----------------------------------------------------------------------

type Props = {
    onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
    const theme = useTheme();
    const { pathname } = useLocation();
    const { themeLayout } = useSettingsContext();
    const { address, isConnected } = useAccount();
    const selectedNetwork = useSelector(state => selectNetworkSelector(state));

    const isNavHorizontal = themeLayout === 'horizontal';

    const isNavMini = themeLayout === 'mini';

    const isDesktop = useResponsive('up', 'lg');

    const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
    const tokens = useSelector((state) => selectAllNetworkTokensSelector(state));

    const reloadVisible =
      pathname === PATH_DASHBOARD.general.dashboard ||
        pathname.includes(PATH_DASHBOARD.general.assets) ||
      pathname === PATH_DASHBOARD.general.analytics;

    const reload = () => {
        if (pathname === PATH_DASHBOARD.general.dashboard || pathname.includes(PATH_DASHBOARD.general.assets)) {
        dispatch(getNetwork(address as string, selectedNetwork.chainId, true));
          dispatch(getTokenPrices(selectedNetwork.chainId, tokens, true));
          dispatch(getStablecoins(true));
      }
      if (pathname === PATH_DASHBOARD.general.analytics) {
        dispatch(
          getNetworks(
            address as string,
            [CHAIN_ID.ETHEREUM, CHAIN_ID.POLYGON, CHAIN_ID.BNB_CHAIN, CHAIN_ID.ARBITRUM],
            true
          )
        );
      }
    };

    const renderContent = (
      <>
        {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}

        {!isDesktop && <Beta />}

        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {isConnected && reloadVisible && (
            <IconButton title={'Reload'} onClick={reload}>
              <Iconify icon={'zondicons:reload'} width={24} />
            </IconButton>
          )}

          {/*<LightDarkPopover/>*/}
          <Wallet />
          {/*<AccountPopover/>*/}
        </Stack>
      </>
    );

    return (
        <AppBar
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(isDesktop && {
                    width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
                    height: HEADER.H_DASHBOARD_DESKTOP,
                    ...(isOffset && {
                        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                    }),
                    ...(isNavHorizontal && {
                        width: 1,
                        bgcolor: 'background.default',
                        height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
                        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
                    }),
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
                    }),
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}
