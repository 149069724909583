import { Avatar, Stack, Typography } from '@mui/material';
import Image from '../../../../components/image';
import { IToken } from '../../../../@types/network';
import { Network } from '../../../../assets/data/networks';

function getInitials(name) {
  const hasTokens = name.indexOf(' ') !== -1;
  return (
    name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
  );
}

export default function TokenTitleRow({
  token,
  network,
  small = false,
}: {
    token: IToken | { logo: string; name: string } | undefined;
    network: Network;
    small?: boolean;
}) {
  return (
    <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
      <div style={{ position: 'relative' }}>
          {token?.logo ? (
              <Image
                  sx={{
                      width: small ? 28 : 32,
                      height: small ? 28 : 32,
                      mr: 0.5,
                      borderRadius: '50%',
                  }}
                  src={token?.logo}
              />
          ) : (
          <Avatar
            sx={{
              mr: 0.5,
              fontSize: 14,
              width: 32,
              height: 32,
            }}
          >
              {getInitials(token?.name)}
          </Avatar>
        )}
        {!small && network.chainId !== 1 && (
          <Image
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 5,
              width: 15,
              height: 15,
              borderRadius: '50%',
            }}
            src={network.iconUri}
          />
        )}
      </div>
      <Typography variant={'subtitle2'}>
          <span style={{whiteSpace: 'nowrap'}}>{token?.name}</span>
      </Typography>
      {
        // coin.symbol === primary?.symbol && <Label sx={{mr: '2em', ml: '3em'}}
        //                                        variant="soft"
        //                                        color={'primary'}>Preferred</Label>
      }
    </Stack>
  );

  //   <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
  //     <Image
  //       sx={{
  //         width: 32,
  //         height: 32,
  //         mr: 0.5,
  //         borderRadius: '50%',
  //       }}
  //       src={icon}
  //     />
  //     <Typography variant={'subtitle2'}>
  //       <span style={{ whiteSpace: 'nowrap' }}>{title}</span>
  //     </Typography>
  //   </Stack>
  // );
}
