// @mui
import {
    Card,
    CardContent,
    Container,
    Link,
    Paper,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow, Typography
} from '@mui/material';
// auth
// _mock_
// components
import {useSettingsContext} from '../../components/settings';
// sections
import {NetworkBalances} from '../../sections/@dashboard/general/e-commerce';
// assets

// ----------------------------------------------------------------------
import React, {useEffect, useRef, memo} from 'react';
import {Helmet} from 'react-helmet-async';
import useScript from '../../hooks/useScript';
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid';
import {Link as RouterLink} from 'react-router-dom';
import {PATH_DASHBOARD} from '../../routes/paths';
import {paramCase} from 'change-case';
import {fDateTime} from '../../utils/formatTime';
import Label from '../../components/label';
import {dispatch, useSelector} from '../../redux/store';
import {getCoinPrices} from '../../redux/slices/coin';
import {getStablecoins} from '../../redux/slices/stablecoin';
import {fCurrency, fNumber, fStableCoinCurrency} from '../../utils/formatNumber';
import Image from '../../components/image';
import TokenTitleRow from '../../sections/@dashboard/general/e-commerce/TokenTitleRow';
import {ETHEREUM} from '../../assets/data/networks';

const getLogoName = (symbol: string) => {
    const found = ETHEREUM.find(e => e.value === symbol);
    return found ? {name: found.label, logo: found.icon} : undefined;
}

function StablecoinsWidget() {

    const {stablecoins} = useSelector((state) => state.stablecoin);

    useEffect(() => {
        dispatch(getStablecoins());
    }, [stablecoins]);

    return (
        <Card>
            <CardContent sx={{padding: '0 !important'}}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Market Cap</TableCell>
                                <TableCell>Volume 24h</TableCell>
                                <TableCell>Circulating Supply</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stablecoins.map((row: any, index) => (
                                <TableRow
                                    key={index}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        <Stack
                                            direction={'row'}
                                            alignItems={'center'}
                                            gap={1}
                                        >
                                            <TokenTitleRow token={getLogoName(row.symbol) as any}
                                                           network={{chainId: 1} as any}/>
                                            <Typography variant="body2" color={'text.secondary'}>
                                                {row.symbol}
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>{fStableCoinCurrency(row.marketCapUsd)}</TableCell>
                                    <TableCell>{fStableCoinCurrency(row.volumeUsd24Hr)}</TableCell>
                                    <TableCell>{fNumber(row.supply)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/*<div style={{ height: 350, width: '100%' }}>*/}
                {/*<DataGrid*/}
                {/*    columns={columns}*/}
                {/*    rows={stablecoins}*/}
                {/*    disableSelectionOnClick*/}
                {/*/>*/}
                {/*</div>*/}
            </CardContent>
        </Card>
    );
}

export default StablecoinsWidget;



