// @mui
import { Container, Stack } from '@mui/material';
// auth
// _mock_
// components
import { useSettingsContext } from '../../components/settings';
// sections
import NetworkBalancesCard from '../../sections/@dashboard/general/e-commerce/NetworkBalancesCard';
// assets

// ----------------------------------------------------------------------

export default function AssetsPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack direction={'column'} gap={2}>
          <NetworkBalancesCard showAssets={true} />
        </Stack>
      </Container>
    </>
  );
}
