import { configureChains, createClient, mainnet } from 'wagmi';
import {arbitrum, bsc, optimism, polygon, base} from '@wagmi/chains';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { WALLET_CONNECT_PROJECT_ID } from './config';

const SUPPORTED_CHAINS = [mainnet, polygon, arbitrum, bsc, optimism, base];

const { provider } = configureChains(SUPPORTED_CHAINS, [
  w3mProvider({ projectId: WALLET_CONNECT_PROJECT_ID }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({
    version: 1,
    chains: SUPPORTED_CHAINS,
    projectId: WALLET_CONNECT_PROJECT_ID,
  }),
  provider,
});

// 3. Configure modal ethereum client
const ethereumClient = new EthereumClient(wagmiClient, SUPPORTED_CHAINS);

export { wagmiClient, ethereumClient, SUPPORTED_CHAINS };
