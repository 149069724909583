// @mui
import {Container, Grid, Stack} from '@mui/material';
// auth
// _mock_
// components
import {useSettingsContext} from '../../components/settings';
// sections
import {NetworkBalances} from '../../sections/@dashboard/general/e-commerce';
import NewsWidget from './NewsWidget';
import React from 'react';
import {Helmet} from 'react-helmet';
import {AppFeatured} from '../../sections/@dashboard/general/app';
import _mock from '../../_mock';
import {ItemProps} from '../../sections/@dashboard/general/app/AppFeatured';
import {_appFeatured} from '../../_mock/arrays';
import StablecoinsWidget from './StablecoinsWidget';
// assets

// ----------------------------------------------------------------------

// export const _appFeatured = [...Array(3)].map((_, index) => ({
//     id: _mock.id(index),
//     title: [
//         'Harry Potter and the Deathly Hallows - Part 2',
//         'Disney Zombies 2',
//         'Lightroom mobile - Koloro',
//     ][index],
//     description: _mock.text.title(index),
//     image: _mock.image.cover(index),
// })

const list: ItemProps[] = [
    {
        id: '1',
        description: 'Protect your assets, get a hardware wallet',
        title: 'Ledger',
        image: '/assets/images/ledger.jpg',
        href: 'https://shop.ledger.com/?r=5302a32e50cb'
    },
    {
        id: '1',
        description: 'Protect your assets, get a hardware wallet',
        title: 'Trezor',
        image: '/assets/images/trezor.jpg',
        href: 'https://affil.trezor.io/aff_c?offer_id=133&aff_id=33677'
    }
]

export default function DashboardPage() {
    const {themeStretch} = useSettingsContext();

    return (
        <>
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7}>
                        <NetworkBalances/>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <AppFeatured list={list}/>
                    </Grid>
                    <Grid item xs={12}>
                        <StablecoinsWidget/>
                    </Grid>
                    <Grid item xs={12}>
                        <NewsWidget/>
                    </Grid>
                </Grid>
            </Container>
    </>
  );
}
