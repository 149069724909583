import {createSlice} from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/axios';
import axios from 'axios';
// @types
//
import {dispatch, store} from '../store';
import {ICoinState} from '../../@types/product';
import {toThreeDecimalPlaces} from '../../utils/number';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    stablecoins: [],
    loaded: false
};

const slice = createSlice({
    name: 'stablecoin',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getStablecoins(state, action) {
            state.isLoading = false;
            state.stablecoins = action.payload;
            state.loaded = true;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStablecoins(force?: boolean) {
    return async () => {
        const state = store.getState();
        if (!force && state.stablecoin.loaded) return;

        dispatch(slice.actions.startLoading());
        try {
            const requestCoinCap = `https://api.coincap.io/v2/assets?ids=${[
                'usd-coin',
                'tether',
                'multi-collateral-dai'
            ].join()}`;
            const response = await axiosInstance.get(requestCoinCap);
            dispatch(slice.actions.getStablecoins(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
