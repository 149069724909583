import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
// utils
// @types
//
import {dispatch, store} from '../store';
import {INetwork, INetworkState, IToken, TokenType} from '../../@types/network';
import {CHAIN_ID, NATIVE_TOKEN_ADDRESS, NETWORKS} from '../../assets/data/networks';
import Moralis from 'moralis';
import axios from '../../utils/axios';
import {getStablecoinIconUriBySymbol} from '../../utils/network';
import {AlchemyOptimismBaseURL} from '../../api-config';

// ----------------------------------------------------------------------

function createGetTokenBalancesRequest(walletAddress: string) {
    const data = JSON.stringify({
        jsonrpc: '2.0',
        method: 'alchemy_getTokenBalances',
        headers: {
            'Content-Type': 'application/json',
        },
        params: [`${walletAddress}`],
        id: 42,
    });
    return {
        method: 'post',
        url: AlchemyOptimismBaseURL,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    };
}

function createGetNativeTokenBalanceRequest(walletAddress: string) {
    const data = JSON.stringify({
        jsonrpc: '2.0',
        method: 'eth_getBalance',
        headers: {
            'Content-Type': 'application/json',
        },
        params: [`${walletAddress}`, 'latest'],
        id: 1,
    });
    return {
        method: 'post',
        url: AlchemyOptimismBaseURL,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
    };
}

function createGetTokenMetadata(contractAddress: string) {
    return {
        method: 'POST',
        url: AlchemyOptimismBaseURL,
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
        },
        data: {
            id: 1,
            jsonrpc: '2.0',
            method: 'alchemy_getTokenMetadata',
            params: [contractAddress],
        },
    };
}

const initialState: INetworkState = {
    isLoading: false,
    error: null,
    selectedChainId: 1,
    chainIdTokens: {},
};

const slice = createSlice({
    name: 'network',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getNetworksSuccess(state, action: PayloadAction<INetwork>) {
            state.isLoading = false;
            state.chainIdTokens = {...state.chainIdTokens, ...action.payload};
        },
        clearNetworks(state) {
            state.isLoading = false;
            state.error = null;
            state.chainIdTokens = {};
        },
        selectChainId(state, action) {
            state.selectedChainId = action.payload;
        },
    },
});

// Actions
export const {clearNetworks, selectChainId} = slice.actions;

//Selectors
export const selectNetwork = (state) => state.network;

const STABLECOIN_SORT_ORDER = ['USDC', 'USDT', 'DAI', 'EUROC', 'PYUSD'];

export function isStablecoin(symbol: string) {
    return !!STABLECOIN_SORT_ORDER.find((s) => s === symbol);
}

export const selectNetworkTokensSelector = createSelector(selectNetwork, (network) => {
    const tokens: IToken[] = network.chainIdTokens[network.selectedChainId];
    if (tokens) {
        const stablecoins = tokens
            .filter((t) => t.tokenType === TokenType.stablecoin)
            .slice()
            .sort(
                (a, b) => STABLECOIN_SORT_ORDER.indexOf(a.symbol) - STABLECOIN_SORT_ORDER.indexOf(b.symbol)
            );
        const others = tokens.filter((t) => !(t.tokenType === TokenType.stablecoin));
        return {stablecoins, others};
    }
    return {
        stablecoins: [],
        others: [],
    };
});

export const selectAllNetworkTokensSelector = createSelector(selectNetwork, (network) =>
    network.chainIdTokens[network.selectedChainId]
        ? network.chainIdTokens[network.selectedChainId]
        : []
);

export const selectNetworkSelector = createSelector(
    selectNetwork,
    (network) => NETWORKS.find((n) => n.chainId === network.selectedChainId)!!
);

export const selectIsNetworkLoadedSelector = createSelector(
    selectNetwork,
    (network) => !!network.chainIdTokens[network.selectedChainId]
);

// Reducer
export default slice.reducer;

function addNativeToken(balance: string) {
    return {
        symbol: '',
        name: undefined,
        logo: null,
        decimals: 18,
        token_address: NATIVE_TOKEN_ADDRESS,
        possible_spam: false,
        balance,
    };
}

async function getOptimismNetwork(walletAddress: string) {
    const balances = await axios(createGetTokenBalancesRequest(walletAddress));
    const nativeBalance = await axios(createGetNativeTokenBalanceRequest(walletAddress));
    const filteredTokens = NETWORKS.find(n => n.chainId === CHAIN_ID.OPTIMISM)?.filteredTokens ?? [];
    const all: IToken[] = [];
    for (let token of balances.data.result.tokenBalances) {
        const respMetadata = await axios(createGetTokenMetadata(token.contractAddress));
        const metadata = respMetadata.data.result;
        const metedata = filteredTokens.find((l) => l.address.toLowerCase() === token.contractAddress);
        const stablecoin = isStablecoin(metadata.symbol);
        all.push({
            symbol: metedata ? metedata.symbol : metadata.symbol,
            balance: parseInt(token.tokenBalance) / Math.pow(10, metadata.decimals),
            chainId: CHAIN_ID.OPTIMISM,
            decimals: metadata.decimals,
            tokenType: stablecoin ? TokenType.stablecoin : TokenType.other,
            name: metedata ? metedata.name : metadata.name,
            logo: metedata?.logoURI,
            tokenAddress: token.contractAddress,
        });
    }
    const nativeBal = nativeBalance.data.result / Math.pow(10, 18);
    if (nativeBal > 0) {
        const lifiMetedata = filteredTokens.find((l) => l.address.toLowerCase() === NATIVE_TOKEN_ADDRESS);
        if (lifiMetedata === undefined) return;
        all.unshift({
            symbol: lifiMetedata.symbol,
            balance: nativeBal,
            logo: lifiMetedata.logoURI,
            tokenAddress: NATIVE_TOKEN_ADDRESS,
            name: lifiMetedata.name,
            decimals: 18,
            tokenType: TokenType.native,
            chainId: CHAIN_ID.OPTIMISM,
        });
    }
    return all.filter((t) => t.balance > 0);
}

export function getNetwork(walletAddress: string, chainId: number, force = false) {

    return async () => {
        const state = store.getState();
        if (!force && state.network.chainIdTokens[chainId]) return;
        dispatch(slice.actions.startLoading());
        try {
            if (chainId === CHAIN_ID.OPTIMISM) {
                const optimismTokens = await getOptimismNetwork(walletAddress);
                if (optimismTokens === undefined) {
                    console.error('Error getting optimism tokens');
                    return;
                }
                dispatch(slice.actions.getNetworksSuccess({[chainId]: optimismTokens}));
            } else {
                const tokenResponse = await Moralis.EvmApi.token.getWalletTokenBalances({
                    address: walletAddress,
                    chain: chainId,
                });
                const nativeTokenResponse = await Moralis.EvmApi.balance.getNativeBalance({
                    address: walletAddress,
                    chain: chainId,
                });
                const filteredTokens = NETWORKS.find(n => n.chainId === chainId)?.filteredTokens ?? [];
                const filteredTokenAddresses = filteredTokens.map(t => t.address.toLowerCase());

                const filtered = [
                    ...[addNativeToken(nativeTokenResponse.toJSON().balance)],
                    ...tokenResponse.toJSON(),
                ]
                    .filter((t) => !t.possible_spam && parseInt(t.balance) > 0 && filteredTokenAddresses.includes(t.token_address))
                    .map((t) => {
                        let token = filteredTokens.find((l) => l.address.toLowerCase() === t.token_address);
                        const stablecoin = isStablecoin(t.symbol);
                        return {
                            chainId,
                            tokenType: stablecoin
                                ? TokenType.stablecoin
                                : t.token_address === NATIVE_TOKEN_ADDRESS
                                    ? TokenType.native
                                    : TokenType.other,
                            symbol: token ? token.symbol : t.symbol as string,
                            name: token ? token.name : t.name as string,
                            logo: token?.logoURI,
                            tokenAddress: t.token_address,
                            decimals: t.decimals,
                            balance: parseInt(t.balance) / Math.pow(10, t.decimals),
                        };
                    });
                dispatch(slice.actions.getNetworksSuccess({[chainId]: filtered}));
            }
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
