// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
import Image from '../../../../components/image';
import { Network, WALLET_NETWORKS } from '../../../../assets/data/networks';
import { useEffect, useState } from 'react';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import { dispatch, useSelector } from '../../../../redux/store';
import {
  getNetwork,
  selectAllNetworkTokensSelector,
  selectChainId,
  selectNetworkSelector,
  selectNetworkTokensSelector,
} from '../../../../redux/slices/network';
import TokenTitleRow from './TokenTitleRow';
import {
  getTokenPrices,
  selectBalancesByNetworkSelector,
  selectTokenBalancesByNetworkSelector,
} from '../../../../redux/slices/tokentPrice';
import { TokensBalanceRow } from './TokensBalanceRow';
import { useAccount } from 'wagmi';
import NetworkAssets from './NetworkAssets';

export function CenterText({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      component={'div'}
      variant={'caption'}
      color={'text.secondary'}
      padding={2}
      align={'center'}
    >
      {children}
    </Typography>
  );
}
interface Props {
    showAssets?: boolean
}


export default function NetworkBalancesCard({showAssets = false}: Props) {
    const {address, isConnected} = useAccount();
    const isLoadingNetworks = useSelector(state => state.network.isLoading);
    const tokens = useSelector(state => selectAllNetworkTokensSelector(state));
    const isLoadingTokenPrices = useSelector(state => state.tokenPrice.isLoading);
    const { stablecoinsBalance, othersBalance } = useSelector(state => selectBalancesByNetworkSelector(state));
    const selectedNetwork: Network = useSelector(state => selectNetworkSelector(state));
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const totalBalance = stablecoinsBalance + othersBalance;

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleChangeChain = (chain: Network) => {
        dispatch(selectChainId(chain.chainId));
        handleClosePopover();
    };

    useEffect(() => {
        if (!isConnected) return;
        dispatch(getNetwork(address as string, selectedNetwork.chainId));
    }, [isConnected, address, selectedNetwork.chainId]);

    useEffect(() => {
        if (!isConnected) return;
        dispatch(getTokenPrices(selectedNetwork.chainId, tokens));
    }, [isConnected, address, selectedNetwork.chainId, tokens]);

    return (
      <Card>
        <CardContent>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="subtitle2" color={'text.primary'}>
              Network Balance
            </Typography>
            <Button
              color={'inherit'}
              variant={'text'}
              endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
              onClick={handleOpenPopover}
              sx={{
                whiteSpace: 'nowrap',
                ...(openPopover && {
                  bgcolor: 'action.selected',
                }),
              }}
            >
              <Image sx={{ width: 24, height: 24, mr: 1 }} src={selectedNetwork.iconUri} />
              <span>{selectedNetwork.title}</span>
              {/*{currentNetwork?.chainId === CHAIN_ID.POLYGON*/}
              {/*    ? <Box*/}
              {/*        sx={{*/}
              {/*            display: 'flex',*/}
              {/*            gap: '0.5em',*/}
              {/*            alignItems: 'center'*/}
              {/*        }}><span>{currentNetwork?.name}</span><Typography*/}
              {/*        variant={'caption'}>(Primary)</Typography></Box>*/}
              {/*    : <span>{currentNetwork?.name}</span>*/}
              {/*}*/}
            </Button>
            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
              <Stack spacing={0.75}>
                {WALLET_NETWORKS.map((option, index) => (
                  <MenuItem
                    key={index}
                    selected={option.chainId === selectedNetwork.chainId}
                    onClick={() => handleChangeChain(option)}
                  >
                    <Image disabledEffect src={option.iconUri} sx={{ width: 24, mr: 1 }} />
                    <span>{option.title}</span>
                    {/*{option.chainId === CHAIN_ID.POLYGON*/}
                    {/*    ? <Box*/}
                    {/*        sx={{*/}
                    {/*            display: 'flex',*/}
                    {/*            gap: '0.5em',*/}
                    {/*            alignItems: 'center'*/}
                    {/*        }}><span>{option.name}</span><Typography*/}
                    {/*        variant={'caption'}>(Primary)</Typography></Box>*/}
                      {/*    : <span>{option.name}</span>*/}
                      {/*}*/}
                  </MenuItem>
                ))}
              </Stack>
            </MenuPopover>
          </Stack>
            <Typography variant={'h3'} paragraph>
                {isLoadingNetworks || isLoadingTokenPrices ?
                    <CircularProgress size={'2rem'} color={'inherit'}/> : fCurrency(totalBalance)}
            </Typography>
            {!showAssets &&
                <Box paddingBottom={2}>
                    {!isConnected && (
                        <Typography
                            component={'div'}
                            variant={'body2'}
                            color={'text.secondary'}
                            paddingBottom={0}
                            align={'center'}
                        >Connect Wallet to see the network balance</Typography>
                    )}
                </Box>
            }
            {showAssets && <>
                <Divider/>
                <NetworkAssets/>
            </>}
        </CardContent>
      </Card>
    );
}
