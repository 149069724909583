// @mui
// redux
// routes
// @types
// components
import { useSettingsContext } from '../../components/settings';
import { LiFiWidget, RouteExecutionUpdate, useWidgetEvents, WidgetConfig, WidgetEvent } from '@lifi/widget';
import { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import palette from '../../theme/palette';
import shadows from '../../theme/shadows';
import { useDisconnect, useSigner, useSwitchNetwork } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { Signer } from 'ethers';
import { SUPPORTED_CHAINS } from '../../wagmi-config';
import { Route } from '@lifi/sdk';
import ReactGA from 'react-ga4';
// sections

// ----------------------------------------------------------------------

export default function SwapPage() {
  const { themeMode } = useSettingsContext();
  const { shape, typography } = useTheme();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();
  const { data: signer } = useSigner();
  const { open } = useWeb3Modal();

  const widgetEvents = useWidgetEvents();

  useEffect(() => {
    const onRouteExecutionStarted = (route: Route) => {
      ReactGA.event('swap', {
        swap_status: 'started',
        swap_id: route.id,
        fromAddress: `_${route.fromAddress}`,
        fromChain: route.fromChainId,
        fromSymbol: route.fromToken.symbol,
        fromAmountUSD: route.fromAmountUSD,
        toChain: route.toChainId,
        toSymbol: route.toToken.symbol

      });
    };
    // const onRouteExecutionUpdated = (update: RouteExecutionUpdate) => {
    //   console.log('onRouteExecutionUpdated fired.', update);
    // };
    const onRouteExecutionCompleted = (route: Route) => {
      ReactGA.event('swap', {
        swap_status: 'completed',
        swap_id: route.id,
        fromAddress: `_${route.fromAddress}`,
        fromChain: route.fromChainId,
        fromSymbol: route.fromToken.symbol,
        fromAmountUSD: route.fromAmountUSD,
        toChain: route.toChainId,
        toSymbol: route.toToken.symbol

      });
    };
    const onRouteExecutionFailed = (update: RouteExecutionUpdate) => {
      ReactGA.event('swap', {
        swap_status: 'failed',
        swap_id: update.route.id,
        fromAddress: `_${update.route.fromAddress}`,
        fromChain: update.route.fromChainId,
        fromSymbol: update.route.fromToken.symbol,
        fromAmountUSD: update.route.fromAmountUSD,
        toChain: update.route.toChainId,
        toSymbol: update.route.toToken.symbol

      });
    };
    // const onRouteHighValueLoss = (update: any) => {
    //   console.log('onRouteHighValueLoss continued.', update);
    // };

    widgetEvents.on(WidgetEvent.RouteExecutionStarted, onRouteExecutionStarted as any);
    // widgetEvents.on(WidgetEvent.RouteExecutionUpdated, onRouteExecutionUpdated);
    widgetEvents.on(WidgetEvent.RouteExecutionCompleted, onRouteExecutionCompleted as any);
    widgetEvents.on(WidgetEvent.RouteExecutionFailed, onRouteExecutionFailed);
    // widgetEvents.on(WidgetEvent.RouteHighValueLoss, onRouteHighValueLoss);

    return () => widgetEvents.all.clear();
  }, [widgetEvents]);

  const widgetConfig: WidgetConfig = useMemo(
      () => ({
        fee: 0.010,
        variant: 'expandable',
        hiddenUI: ['appearance', 'language'],
        containerStyle: {
          maxHeight: 'unset',
        },
        theme: {
          palette: palette(themeMode),
          shape: shape,
          shadows: shadows(themeMode),
          typography: typography,
      },
      chains: {
        allow: SUPPORTED_CHAINS.map((s) => s.id),
      },
      insurance: true,
      integrator: 'DLTPAY',
      walletManagement: {
        signer: signer as Signer | undefined,
        connect: async () => {
          if (!signer) {
            await open();
          }
          return signer!;
        },
        disconnect: async () => {
          disconnect();
        },
        switchChain: async (reqChainId: number) => {
          switchNetwork?.(reqChainId);
          if (signer) {
            return signer!;
          }
          throw Error('No signer object after chain switch');
        },
      },
    }),
    [signer, disconnect, open, themeMode, shape, typography, switchNetwork]
  );

  return <LiFiWidget integrator={'DLTPAY'} config={widgetConfig} />;
}
