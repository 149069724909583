import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
// utils
// @types
//
import {dispatch, store} from '../store';
import {IToken, ITokenPrice, ITokenPriceState, TokenType} from '../../@types/network';
import {NETWORKS} from '../../assets/data/networks';
import axios from '../../utils/axios';
import {toTwoDecimalPlaces} from '../../utils/number';
import {selectNetwork} from './network';
import Moralis from 'moralis';
import {LIFI_KEY} from '../../config';

// ----------------------------------------------------------------------

const initialState: ITokenPriceState = {
    isLoading: false,
    error: null,
    prices: {},
};

const slice = createSlice({
    name: 'tokenPrice',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getTokenPricesSuccess(state, action: PayloadAction<ITokenPrice>) {
            state.isLoading = false;
            state.prices = {...state.prices, ...action.payload};
        },
        clearTokenPrices(state) {
            state.isLoading = false;
            state.error = null;
            state.prices = {};
        },
    },
});

// Actions
export const {clearTokenPrices} = slice.actions;

//Selectors
const selectTokenPrice = (state) => state.tokenPrice;

export const selectTokenBalancesByNetworkSelector = createSelector(
    [selectTokenPrice, selectNetwork],
    (tokenPrice, network) =>
        tokenPrice.prices[network.selectedChainId] ? tokenPrice.prices[network.selectedChainId] : []
);

export const selectBalancesByNetworkSelector = createSelector(
    [selectTokenPrice, selectNetwork],
    (tokenPrice, network) =>
        tokenPrice.prices[network.selectedChainId]
            ? tokenPrice.prices[network.selectedChainId].reduce(
                (acc, cur) => {
                    if (cur.tokenType === TokenType.stablecoin) {
                        acc.stablecoinsBalance += cur.balanceUSD;
                    } else {
                        acc.othersBalance += cur.balanceUSD;
                    }
                    return acc;
                },
                {stablecoinsBalance: 0, othersBalance: 0}
            )
            : {stablecoinsBalance: 0, othersBalance: 0}
);

// Reducer
export default slice.reducer;

export function getTokenPrices(chainId: number, tokens: IToken[], force = false) {
    return async () => {
        if (tokens.length === 0) return;
        const state = store.getState();
        if (!force && state.tokenPrice.prices[chainId]) return;
        dispatch(slice.actions.startLoading());
        try {
            const tokenAddresses = tokens.filter(t => t.tokenType !== 1).map((t) => ({tokenAddress: t.tokenAddress}));
            const nativeToken = tokens.filter(t => t.tokenType === 1);
            let all: any[] = [];
            if (tokenAddresses.length !== 0) {
                const response = await Moralis.EvmApi.token.getMultipleTokenPrices({
                    chain: chainId.toString(),
                }, {tokens: tokenAddresses});
                all = response.raw as [];
            }
            const nativeAddress = nativeToken.length === 1 ? nativeToken[0].tokenAddress : undefined;
            if (nativeAddress) {
                const nativeResponse = await axios.get('https://li.quest/v1/token', {
                    params: {
                        chain: chainId,
                        token: nativeAddress
                    }, headers: {'x-api-key': LIFI_KEY}
                });
                const priceUSD = parseFloat(nativeResponse.data.priceUSD)
                all = [...all, {tokenAddress: nativeAddress, usdPrice: priceUSD}];
            }
            const mappedPrices = all.filter(f => !!f).map((t) => {
                const token = tokens.find((iT) => iT.tokenAddress === t.tokenAddress);
                return {
                    tokenType: token?.tokenType as TokenType,
                    tokenAddress: token?.tokenAddress as string,
                    priceUSD: t.usdPrice !== undefined ? toTwoDecimalPlaces(t.usdPrice) : undefined,
                    balanceUSD: token?.balance !== undefined && t.usdPrice !== undefined ? toTwoDecimalPlaces(token.balance * t.usdPrice) : undefined,
                };
            });
            dispatch(slice.actions.getTokenPricesSuccess({[chainId]: mappedPrices}));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}